import { usePatientStore } from '@/stores/patients';
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  UseModalProps,
  Button,
  ButtonGroup,
  IconButton,
  HStack,
  VStack,
  Input,
  FormLabel,
  FormErrorMessage,
  FormControl,
  Alert,
  AlertIcon,
  AlertDescription,
  Select,
  Switch,
  Box,
  Flex,
  SimpleGrid,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Spinner,
  useDisclosure,
  Icon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { PlusIcon, XIcon } from '@heroicons/react/solid';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { ClinicDevicesRow, useClinicDevices } from '../api/getClinicDevices';
import { useVVPProducts } from '../api/getVVPProducts';
import { ManageHeadsetModal } from './ManageHeadsetModal';
import { useAuth } from '@/lib/auth';
import { useSetAssignHeadset } from '../api/setAssignHeadset';

export const ManageHeadsetsModal: React.FC<UseModalProps> = ({ isOpen, onClose }) => {
  const { data, isLoading, refetch, isFetching } = useClinicDevices({});

  const { data: vvpProductsData, isLoading: vvpProductsLoading } = useVVPProducts({});

  const { patients } = usePatientStore();

  const [selectedHeadset, setSelectedHeadset] = useState<ClinicDevicesRow | null>(null);

  const setAssignHeadset = useSetAssignHeadset();

  const handleUnassignHeadset = async (headset: ClinicDevicesRow | null) => {
    if (headset)
      await setAssignHeadset.mutateAsync({
        clinic_device_id: headset.clinic_device_id,
        clinic_user_id: 0,
      });
  };

  const {
    isOpen: isManageHeadsetOpen,
    onClose: onManageHeadsetClose,
    onOpen: onManageHeadsetOpen,
  } = useDisclosure();

  const {
    isOpen: isConfirmUnassignHeadsetOpen,
    onOpen: onConfirmUnassignHeadsetOpen,
    onClose: onConfirmUnassignHeadsetClose,
  } = useDisclosure();

  const cancelUnassignHeadsetRef = useRef<HTMLButtonElement>(null);

  // console.log('clinicDevices', data);

  useEffect(() => {
    refetch();
  }, [isOpen]);

  const assignedPatient = (clinic_user_id: number) => {
    const p = patients.find((p) => p.clinic_user_id === clinic_user_id);

    if (clinic_user_id == 0) return <Text color="gray.400">Unassigned</Text>;

    return p ? (
      <>
        {p.user_name} ({p.home_activation_code}){' '}
      </>
    ) : (
      <Text color="red.400">Not Found</Text>
    );
  };

  const { user } = useAuth();

  return (
    <>
      <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="64rem">
          <ModalHeader>Headsets</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading ? (
              <Flex h="5rem" justifyContent="center" align="center">
                <Spinner size="xl" />
              </Flex>
            ) : (
              <>
                <TableContainer className="mb-3">
                  <Table size="sm">
                    {/* <TableCaption>
                      {!isLoading && user?.flags?.includes('PHARMA') === false && (
                        <Flex className="mb-3 space-x-3 justify-end" alignItems="right">
                          {vvpProductsData &&
                            Object.keys(vvpProductsData['live']).map((product_name) => (
                              <Button
                                key={product_name}
                                leftIcon={<Icon as={PlusIcon} w={6} h={6} />}
                                colorScheme="blue"
                                variant="outline"
                                size="sm"
                                onClick={() =>
                                  window.open(vvpProductsData['live'][product_name], '_blank')
                                }
                              >
                                {product_name}
                              </Button>
                            ))}
                          ;
                        </Flex>
                      )}
                    </TableCaption> */}
                    <Thead>
                      <Tr>
                        <Th>Headset</Th>
                        <Th>Serial No.</Th>
                        {/* <Th>License</Th> */}
                        <Th>Assigned To</Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data &&
                        data.map((headset) => {
                          return (
                            <Tr key={headset.clinic_device_id}>
                              <Td>{headset.device_alias}</Td>
                              <Td>
                                <Text fontSize="2xs">{headset.device_serial_number}</Text>
                              </Td>
                              {/* <Td>{headset.sub_token_url}</Td> */}
                              <Td>
                                <Flex>
                                  {headset.clinic_user_id != 0 ? (
                                    <ButtonGroup
                                      flexGrow={1}
                                      size="sm"
                                      isAttached
                                      variant="outline"
                                      key={headset.clinic_device_id}
                                    >
                                      <Button
                                        flexGrow={1}
                                        color="blue.500"
                                        pointerEvents="none"
                                        borderColor="blue.500"
                                      >
                                        {assignedPatient(headset.clinic_user_id)}
                                      </Button>
                                      <IconButton
                                        colorScheme="blue"
                                        variant="solid"
                                        aria-label="Unassign Headset"
                                        icon={<Icon as={XIcon} />}
                                        onClick={() => {
                                          setSelectedHeadset(headset);
                                          onConfirmUnassignHeadsetOpen();
                                        }}
                                      />
                                    </ButtonGroup>
                                  ) : (
                                    assignedPatient(headset.clinic_user_id)
                                  )}
                                </Flex>
                              </Td>
                              <Td textAlign="right">
                                <Button
                                  colorScheme="blue"
                                  size="sm"
                                  onClick={() => {
                                    setSelectedHeadset(headset);
                                    onManageHeadsetOpen();
                                  }}
                                >
                                  Manage
                                </Button>
                              </Td>
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                </TableContainer>
                <AlertDialog
                  isOpen={isConfirmUnassignHeadsetOpen}
                  leastDestructiveRef={cancelUnassignHeadsetRef}
                  onClose={onConfirmUnassignHeadsetClose}
                >
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Unassign Headset
                      </AlertDialogHeader>

                      <AlertDialogBody>
                        Are you sure you want to unassign this Headset?
                      </AlertDialogBody>

                      <AlertDialogFooter>
                        <Button
                          ref={cancelUnassignHeadsetRef}
                          onClick={() => {
                            setSelectedHeadset(null);
                            onConfirmUnassignHeadsetClose();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          colorScheme="red"
                          isLoading={setAssignHeadset.isLoading}
                          onClick={() => {
                            handleUnassignHeadset(selectedHeadset).then(() => {
                              onConfirmUnassignHeadsetClose();
                            });
                          }}
                          ml={3}
                        >
                          Unassign
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
              </>
            )}
            {/* <iframe
              title="foxy"
              sandbox="allow-forms allow-modals"
              src="https://vividvision.foxycart.com/cart?name||27c021fefdb9accb0ffb9d4c378d9007739855688cc7cd6d1120fa08fff3444e=VVP%20Device%20-%20Unlimited%20Patients&code||dfa1833c3fb4c164c9fe133ec6ac2ececad1e776b19d9c8ae0d1fd0b98d70fbf=vvp-unlimited&price||9d807e0c18eede9446b10d5f571cf100e94254f321de9f84266dd96ffc783f18=249usd&image||05778ad1bb018ea1746f5c656ad2a1adafe2c2654770c3cf12040f3c05cfd9f7=https://www.seevividly.com/images/Vivid_Vision_Home_store_nobox.png&category||d10bf276cf980b88ba0314af804e08d4f1fce82f26150f1cb40b3d3abe2a2b64=Bundle&sub_frequency||b491760e463155f83fdf783a61318297383110ba14bdf9f12f57c60d4d65563b=30d&description||2c215d1b822a918066c40fd21736341a9ba76f0b6c9cbec4b90477650363437b=Subscription%20provides%20access%20for%20any%20number%20of%20patients%20from%20this%20device.%20VR%20hardware%20included%20with%20subscription.%20Return%20the%20device%20within%2030%20days%20to%20get%20your%20money%20back."
              style={{ width: '100%', height: '600px' }}
            /> */}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ManageHeadsetModal
        clinic_device={selectedHeadset}
        isOpen={isManageHeadsetOpen}
        onClose={onManageHeadsetClose}
      />
    </>
  );
};

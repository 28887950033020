/* eslint-disable react/no-children-prop */
import { usePatientStore } from '@/stores/patients';
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  UseModalProps,
  Button,
  HStack,
  VStack,
  Spacer,
  Input,
  FormLabel,
  FormErrorMessage,
  FormControl,
  Alert,
  AlertIcon,
  AlertDescription,
  Select,
  Switch,
  Box,
  Flex,
  SimpleGrid,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Spinner,
  InputGroup,
  InputRightElement,
  Icon,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ClinicDevicesRow, useClinicDevices } from '../api/getClinicDevices';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteTag,
} from '@choc-ui/chakra-autocomplete';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { useSetAssignHeadset } from '../api/setAssignHeadset';
import { Patient } from '../types';
import { useAuth } from '@/lib/auth';

export interface UseVVManageHeadsetModalProps extends UseModalProps {
  clinic_device?: ClinicDevicesRow | null;
}

export const ManageHeadsetModal: React.FC<UseVVManageHeadsetModalProps> = ({
  isOpen,
  onClose,
  clinic_device,
}) => {
  const { patients } = usePatientStore();
  const setAssignHeadset = useSetAssignHeadset();
  const { user } = useAuth();

  // const assignedPatient = (clinic_user_id: number) => {
  //   const p = patients.find((p) => p.clinic_user_id === clinic_user_id);

  //   if (clinic_user_id == 0) return <Text color="gray.400">Unassigned</Text>;

  //   return p ? (
  //     <>
  //       {p.user_name} ({p.home_activation_code}){' '}
  //     </>
  //   ) : (
  //     <Text color="red.400">Not Found</Text>
  //   );
  // };

  const [assignedPatientId, setAssignedPatientId] = useState<number>(
    clinic_device?.clinic_user_id || 0
  );

  const [headsetAlias, setHeadsetAlias] = useState<string>(clinic_device?.device_alias || '');

  useEffect(() => {
    setAssignedPatientId(clinic_device?.clinic_user_id || 0);
    setHeadsetAlias(clinic_device?.device_alias || '');
  }, [clinic_device]);

  return (
    <>
      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          {clinic_device && (
            <>
              <ModalHeader>Manage Headset</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <SimpleGrid
                  columns={2}
                  spacingX={5}
                  spacingY={4}
                  fontSize="sm"
                  mx={5}
                  mb={4}
                  alignItems="center"
                >
                  <Box>
                    <Text fontWeight="bold">Headset</Text>
                  </Box>
                  <Box>
                    <Input
                      size="sm"
                      value={headsetAlias}
                      onChange={(e) => {
                        setHeadsetAlias(e.target.value);
                      }}
                    />
                  </Box>
                  <Box>
                    <Text fontWeight="bold">Assigned To</Text>
                  </Box>
                  {/* <Box>{assignedPatient(clinic_device.clinic_user_id)}</Box> */}
                  <Box>
                    <AutoComplete
                      openOnFocus
                      emptyState="No patients found"
                      onChange={(value) => {
                        console.log('value', value);
                        setAssignedPatientId(value);
                      }}
                    >
                      {({ isOpen }) => (
                        <>
                          <InputGroup size="sm">
                            <AutoCompleteInput
                              placeholder="Search..."
                              value={
                                patients.find((p) => p.clinic_user_id === assignedPatientId)
                                  ?.user_name || ''
                              }
                              onChange={(value) => {
                                setAssignedPatientId(0);
                              }}
                            ></AutoCompleteInput>
                            <InputRightElement
                              children={<Icon as={isOpen ? ChevronUpIcon : ChevronDownIcon} />}
                            />
                          </InputGroup>
                          <AutoCompleteList>
                            {/* <AutoCompleteItem key={`option-unassigned`} value={0}>
                              <Text display="inline-block" mr="1" fontWeight="bold">
                                {`Unassigned`}
                              </Text>
                            </AutoCompleteItem> */}
                            {patients.map((p) => (
                              <AutoCompleteItem
                                key={`option-${p.clinic_user_id}`}
                                value={p.clinic_user_id}
                                // getValue={(item) => item.clinic_user_id}
                                label={p.user_name}
                              >
                                <Text display="inline-block" mr="1" fontWeight="bold">
                                  {p.user_name}
                                </Text>{' '}
                                ({p.home_activation_code})
                              </AutoCompleteItem>
                            ))}
                          </AutoCompleteList>
                        </>
                      )}
                    </AutoComplete>
                  </Box>
                  {/* <Box>
                    <Text fontWeight="bold">License</Text>
                  </Box>
                  <Box>{clinic_device?.sub_token_url}</Box> */}
                  <Box>
                    <Text fontWeight="bold">Serial No.</Text>
                  </Box>
                  <Box>{clinic_device?.device_serial_number}</Box>
                </SimpleGrid>
              </ModalBody>

              <ModalFooter>
                {user?.flags?.includes('PHARMA') === false && (
                  <Button colorScheme="red" ml={0} disabled>
                    Cancel Subscription
                  </Button>
                )}
                <Spacer />
                <Button mr={3} onClick={onClose}>
                  Close
                </Button>
                <Button
                  colorScheme="blue"
                  mr={3}
                  isLoading={setAssignHeadset.isLoading}
                  onClick={() => {
                    if (clinic_device.clinic_device_id) {
                      setAssignHeadset
                        .mutateAsync({
                          clinic_device_id: clinic_device.clinic_device_id,
                          clinic_user_id: assignedPatientId,
                          device_alias: headsetAlias,
                        })
                        .then((resp) => {
                          if (resp.success) onClose();
                        });
                    }
                  }}
                >
                  Save
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
